@mixin range-track {
  &::-webkit-slider-runnable-track {
    @content;
  }
  &::-moz-range-track {
    @content;
  }
  &::-ms-track {
    @content;
  }
}

@mixin range-thumb {
  &::-webkit-slider-thumb {
    @content;
  }
  &::-moz-range-thumb {
    @content;
  }
  &::-ms-thumb {
    @content;
  }
}

[type='range'] {
  appearance: none;
  background: var(--border-light) no-repeat;
  border-radius: 0.25em;
  border: thin solid var(--border);
  height: 1.5em;
  width: 100%;
  padding: 0;

  @include range-track {
    appearance: none;
    background: none;
    cursor: pointer;
  }

  @include range-thumb {
    appearance: none;
    background: transparent;
    border: medium solid var(--text);
    border-radius: 0.25em;
    box-shadow: 0 0 0 1px var(--bg), 0 0 0 1px var(--bg) inset;
    cursor: pointer;
    height: 1.5em;
    width: 1em;
  }
}

[data-ccs-input='lightness'] {
  background-image: linear-gradient(
    to right,
    var(--range-dark),
    var(--range-light)
  );
}

[data-ccs-input='contrast'] {
  background-image: linear-gradient(
      to right,
      var(--ccs-neutral--fg1),
      var(--ccs-neutral--fg-full)
    ),
    linear-gradient(
      to right,
      var(--ccs-neutral--bg1),
      var(--ccs-neutral--bg-full)
    );
  background-size: 100% 50%, 100% 100%;
  background-position: top left, bottom left;
}
