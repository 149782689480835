$root-static: 125%;
$root-calc: calc(100% + 0.25vw);

$xlarge: min(1.5rem + 1.5vw, 3rem);
$large: min(1rem + 1vw, 1.5rem);
$small: 0.85rem;

$gutter: 0.75rem;
$double-gutter: $gutter * 2;
$spacer: calc(#{$gutter * 3} + 2vw);
$shim: $gutter / 2;
$half-shim: $gutter / 4;
$quarter-shim: $gutter / 8;

$radius: $quarter-shim;

$measure: 65ch;
$break: 45em;
