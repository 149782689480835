[data-svg='neck'] {
  grid-area: img / over / img / img;
  position: relative;
  width: 100%;
  z-index: -1;
}

.ink {
  animation: float 28s -10s linear infinite;
  mix-blend-mode: soft-light;
}

.pencil {
  animation: float 34s -23s linear infinite;
}

.ink,
.pencil {
  animation-play-state: var(--animate, paused);
}

@keyframes float {
  0%,
  5%,
  42%,
  48%,
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  10% {
    transform: translate3d(-1px, -3px, 0) scale(1.006);
  }
  20% {
    transform: translate3d(-5px, 0, 0) scale(1);
  }
  30% {
    transform: translate3d(0, 3px, 0) scale(0.994);
  }
  40% {
    transform: translate3d(1px, -1px, 0) scale(0.994);
  }
  50% {
    transform: translate3d(-1px, 3px, 0) scale(1);
  }
  60% {
    transform: translate3d(-5px, 1px, 0) scale(1.006);
  }
  70% {
    transform: translate3d(3px, 1px, 0) scale(1.01);
  }
  80% {
    transform: translate3d(-1px, -1px, 0) scale(1.01);
  }
  90% {
    transform: translate3d(3px, 3px, 0) scale(1);
  }
  100% {
    transform: translate3d(1px, -3px, 0) scale(1);
  }
}
