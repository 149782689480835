@use '../config/tools';
@use '~cascading-color-systems/index' as ccs with (
  $hues: (
    'prime': 355,
    'neutral': 195,
  ),
  $saturation: 75%,
  $lightness: 50%,
  $contrast: 45%,
  $steps: 6,
  $fade-background: 20%,
  $fades: 0.85,
  $gradients: true,
  $build: true
);

[data-ccs-colors] {
  @include tools.tokens--(tools.$colors);
  --shadow-l: #{ccs.if-mode(var(--ccs-l--fg6), var(--ccs-l--bg6))};

  --card-table-l: #{ccs.if-mode(var(--ccs-l--bg5), var(--ccs-l--bg6))};
  --card-l: #{ccs.if-mode(var(--ccs-l--bg6), var(--ccs-l--bg5))};

  --darker-l: #{ccs.if-mode(var(--ccs-l--fg5), var(--ccs-l--bg5))};
  --lighter-l: #{ccs.if-mode(var(--ccs-l--bg5), var(--ccs-l--fg5))};
}

[data-ccs-colors='auto'] {
  --ccs-mode--user: initial;
}
