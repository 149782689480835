@use '../config/scale';
@use '../config/tools';

@include tools.import-webfonts();

html {
  @include tools.font-family('slab');
  font-size: scale.$root-static;
  font-size: scale.$root-calc;
  line-height: 1.4;
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

h1,
h2 {
  line-height: 1.2;
  margin: 0;
}

h2,
h3,
h4 {
  font-size: 1rem;
}

blockquote {
  border-left: var(--shim) solid var(--border-light);
  margin: 0 0 0 calc(var(--shim) * -1);
  padding: 0 0 0 var(--gutter);
}

figure {
  margin: 0;
  padding: 0;
}

[hidden] {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}
