@forward '~accoutrement/sass/tools' with (
  $font-path: '../fonts/'
);

@use '~accoutrement/sass/tools';

/// Consistent focus ring
/// is applied by default on element-focus,
/// but some patterns (like radio-buttons) also require
/// focus-ring applied to a sibling or parent element.
/// @example html
///   <div data-ccs-colors>
///     <button data-btn data-styleguide="focus">
///       focus-ring example
///     </button>
///   </div>
/// @group config-focus
@mixin focus-ring() {
  outline: medium dotted currentColor;
  outline-color: var(--focus-ring, currentColor);
  outline-offset: var(--half-shim);
}

tools.$z-index: ('bump', 'overlay');
