@use '../config/tools';

/// # Radio Buttons
/// @group input-radio

/// @group input-radio
[data-radio='group'] {
  --focus-ring: var(--text);
  --radio-border: var(--text);
  --radio-focus: var(--action);
  display: flex;
}

/// @group input-radio
[data-radio='input'] {
  @include tools.is-hidden;
}

/// @group input-radio
[data-radio='option'] {
  border: thin solid var(--radio-border);
  border-radius: var(--first, 0) var(--last, 0) var(--last, 0) var(--first, 0);
  cursor: pointer;
  flex: auto;
  padding: var(--quarter-shim) var(--gutter);
  text-align: center;
  text-transform: capitalize;

  [data-radio='input']:checked + & {
    --radio-border: var(--radio-focus);
    box-shadow: 0 0 0 var(--quarter-shim) var(--radio-focus) inset;
    color: var(--action);

    &::before {
      content: '✓ ';
    }
  }

  [data-radio='input']:focus + & {
    @include tools.focus-ring;
    @include tools.z-index('bump');
    position: relative;
  }

  &:first-of-type {
    --first: var(--radius);
  }

  &:last-of-type {
    --last: var(--radius);
  }
}
