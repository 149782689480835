@use '../config/scale';

.page-header {
  border-bottom: medium dashed var(--border);
  display: grid;
  grid-template:
    '.      .     .' var(--spacer)
    't-line title .' auto
    's-line sub   .' auto
    '.      .     .' var(--gutter)
    '.      nav   .' auto
    '.      .     .' var(--spacer)
    / minmax(var(--gutter), 1fr) minmax(min-content, var(--measure)) minmax(var(--gutter), 1fr);

  &::before,
  &::after {
    align-self: center;
    background: linear-gradient(to top, var(--border), var(--border)),
      radial-gradient(circle closest-side, var(--border) 99%, transparent 100%);
    background-position: 0 center, 100% center;
    background-repeat: no-repeat;
    background-size: 100% 2px, 6px 6px;
    content: '';
    height: 6px;
    margin-right: var(--gutter);
  }

  &::before {
    grid-area: t-line;
  }

  &::after {
    grid-area: s-line;
  }
}

#cover {
  --overlap: calc(var(--double-gutter) + 3vw);
  grid-template:
    '.      .     .     .' var(--gutter)
    't-line title title .' auto
    's-line sub   sub   .' auto
    '.      .     .     .' var(--shim)
    '.      nav   nav   .' auto
    '.      .     .     .' var(--gutter)
    '.      over  img   img' 1fr
    / minmax(var(--gutter), 0.5fr) var(--overlap) auto minmax(var(--gutter), 1fr);

  @media (min-width: scale.$break * 0.5) {
    grid-template:
      '.      .     .     .' var(--gutter)
      't-line title title .' auto
      's-line sub   sub   .' auto
      '.      .     .     .' var(--gutter)
      '.      .     over  img' 2fr
      '.      nav   nav   img' auto
      '.      .     .     img' 1fr
      / minmax(var(--gutter), 0.5fr) var(--overlap) auto minmax(var(--gutter), 1fr);
  }

  @media (min-width: scale.$break) {
    --overlap: calc(var(--spacer) + 3vw);
    grid-template:
      '.      .     over  img' minmax(var(--double-gutter), 3fr)
      't-line title title img' auto
      's-line sub   sub   img' auto
      '.      .     .     img' var(--gutter)
      '.      nav   nav   img' auto
      '.      .     .     img' minmax(var(--gutter), 1fr)
      / 1fr max-content var(--overlap) minmax(auto, 40vw);
  }

  &[data-animate='true'] {
    --animate: running;
  }
}

#title {
  --link: var(--text);
  --underline-color: var(--link);
  font-size: var(--xlarge);
  grid-area: title;
}

#subtitle,
#byline {
  color: var(--text-light);
  font-size: var(--large);
  grid-area: sub;
  margin: 0;
}

.meta {
  grid-area: nav;
}

[data-toggle='animate'] {
  grid-area: -2 / -3 / span 1 / span 2;
  place-self: end;
  margin: var(--gutter);
}
