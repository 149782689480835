@use '../config/tools';
@use '../config/scale';
@use 'sass:math';

#controls {
  margin: var(--spacer) auto;
  max-width: max-content;
  padding: 0 var(--shim);
  text-align: center;

  p {
    margin: var(--shim) 0 0;
  }
}

[data-cards] {
  --card-padding: var(--gutter);
  background: var(--card-table);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(45ch, 100%), 1fr));
  gap: var(--gap, 0);
  justify-content: center;
  justify-items: center;
  margin: var(--spacer) var(--card-padding);

  &[data-column='true'] {
    grid-template-columns: minmax(min-content, var(--measure));
  }

  &[data-align='true'] {
    --align: 0;
    --gap: var(--gutter);
  }

  @media (min-width: scale.$break) {
    --card-padding: var(--double-gutter);
  }
}

[data-card] {
  --show-id: ;
  --rotate: var(--align, calc(var(--amt, 2deg) * var(--dir, -1)));
  background: var(--card);
  border: thin solid var(--border-light);
  box-shadow: 0 0 var(--offset, var(--half-shim)) var(--shadow);
  display: grid;
  grid-template: 'content' 1fr / 100%;
  margin: 0;
  max-width: var(--measure);
  padding: var(--card-padding);
  position: relative;
  transform: rotate(var(--rotate)) scale(var(--scale, 1));
  transition: transform 150ms ease-out;
  width: 100%;

  @for $i from 1 through 6 {
    &:nth-child(3n + #{$i}),
    &:nth-child(5n + #{$i}) {
      --amt: #{math.random(6) * 0.25deg + math.random(6) * 0.5deg};
    }
    &:nth-child(5n + #{$i}),
    &:nth-child(7n + #{$i}) {
      --dir: #{if($i % 2 == 0, 1, -1)};
    }
  }

  &::before {
    content: '';
    display: block;
    grid-area: content / content / caption;
    height: 0;
    padding-bottom: calc(100% / (17 / 9));
  }

  &::after {
    @include tools.position(0 0 null null);
    background: var(--bg);
    border-radius: var(--radius);
    content: var(--show-id, attr(id));
    font-size: var(--small);
    font-style: italic;
    margin: var(--half-shim);
    padding: var(--quarter-shim) var(--shim);
  }
}

[data-card~='caption'] {
  grid-gap: var(--gutter);
  grid-template: 'content' 1fr 'caption' auto / 100%;
}

[data-card~='full'] {
  padding: 0;
}

[data-content] {
  align-self: center;
  grid-area: content;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

[data-content='embed'] {
  align-self: stretch;
  width: 100%;
  height: auto;
}

[data-content='image'] {
  align-self: stretch;
}

[data-caption] {
  color: var(--text-light);
  grid-area: caption;
  text-align: right;
}
